<template>
      <!-- <nav class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <a class="navbar-brand" href="#"><img src="frontend/images/logo/logo.png" alt=""></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item">
                <router-link to="/" class="nav-link"> 
                Home 
                </router-link>
              </li>
              
              <li class="nav-item">
                <router-link to="/aboutview" class="nav-link">
                About
                </router-link>
              </li>

              <li class="nav-item">
                <router-link to="/oueteam" a class="nav-link">
                Our Team
                </router-link>
              </li>
              
              <li class="nav-item">
                <router-link to="/servicepage" class="nav-link dropdown">
                Services
                </router-link>
              </li>

              
              <li class="nav-item">
                <a class="nav-link" href="#">Testimonials</a>
              </li>


              <li class="nav-item">
                <router-link to="/blogpage" class="nav-link active">
                 Blog
                </router-link>
              </li>


              <li class="nav-item">
                <router-link to="/contactpage" class="nav-link">
                 Contact
                </router-link>
              </li>
              

            </ul>
            <form class="nav-fr-btn">
                <button class="btn" type="button">Get Started</button>
            </form>
          </div>
        </div>
      </nav> -->
     <NavBar/>
     <!-- navigationend -->





     <!-- banner start here -->

     <div class="banner-content banner-content-about">
      <div class="hero-text">
        <h2><strong>Blog Details</strong></h2>
        <p> We’re Very Experience In Digital marketing  </p>
        <div class="hero-button-sctn">
         <button class="btn">Let's Work Together</button>
        </div>
      </div>
      <div class="shape-float">
        <img class="img-posin-1" src="frontend/images/banner/Shape1.png" alt="">
        <img class="img-posin-2" src="frontend/images/banner/Shape2.png" alt="">
      </div>
    </div>
     
     <!-- banner end -->






     <!-- Blog Details Section start here  -->


    <div class="blog-dtl-sect">

      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="content">
              <div class="item">
                <div class="head-box">
                  <h4>Table of Contents</h4>
                  <span class="dwn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#2a9fd6" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                    </svg>
                  </span>
                </div>
                <p class="mb-5">Vivamus vitae velit ac lorem volutpat convallis. Sed tempor euismod neque in porta. Nam ut pulvinar erat, eget tincidunt dolor. Nulla vel nibh dapibus, pellentesque felis nec, convallis quam. Morbi elit nibh, vehicula ut mi at, volutpat commodo nisl. Cras sed justo elementum, malesuada dui sed, tristique elit. Sed imperdiet pharetra erat, vel commodo nunc aliquet sed.  </p>

                <p class="mb-5">Integer facilisis tellus non porta vulputate. Fusce sit amet porttitor est, eu mattis augue. Nunc pretium suscipit venenatis. Curabitur dictum maximus nibh at vulputate. In molestie nulla non arcu dictum tristique.</p>

                <p><strong>Ready to go fetch this information? <a href="#" class="btn">Let’s get started!</a></strong></p>
              </div>
              <div class="item mt-55">
                <h3>Nam mattis placerat auctor usce?</h3>
                <p class="mb-5">Vivamus vitae velit ac lorem volutpat convallis. Sed tempor euismod neque in porta. Nam ut pulvinar erat, eget tincidunt dolor. Nulla vel nibh dapibus, pellentesque felis nec, convallis quam. Morbi elit nibh, vehicula ut mi at, volutpat commodo nisl. Cras sed justo elementum, malesuada dui sed, tristique elit. Sed imperdiet pharetra erat, vel commodo nunc aliquet sed.</p>
                <p class="mb-5">Integer facilisis tellus non porta vulputate. Fusce sit amet porttitor est, eu mattis augue. Nunc pretium suscipit venenatis. Curabitur dictum maximus nibh at vulputate. In molestie nulla non arcu dictum tristique.</p>
                <div class="list-ner">
                  <h5>That means you need to keep a few things in mind, such as:</h5>
                  <ul>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Vivamus vitae velit ac lorem volutpat convallis. 
                    </li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Sed tempor euismod neque in porta. Nam ut pulvinar erat, eget tincidunt dolor. 
                    </li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Nulla vel nibh dapibus, pellentesque felis nec, convallis quam. Morbi elit nibh, vehicula ut mi at, volutpat commodo nisl. 
                    </li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Cras sed justo elementum, malesuada dui sed, tristique elit. Sed imperdiet pharetra erat, vel commodo nunc aliquet sed. 
                    </li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Integer facilisis tellus non porta vulputate. 
                    </li>
                  </ul>
                </div>
              </div>
              <div class="item mt-75">
                <h3>Nam a velit id velit pulvinar rutrum</h3>
                <img class="img-ful mb-65" src="frontend/images/blog/Rectangle 29-1.png" alt="">
              </div>
              <div class="item">
                <h3>Phasellus eget entesque egestas neque</h3>
                <p class="mb-5">Vivamus vitae velit ac lorem volutpat convallis. Sed tempor euismod neque in porta. Nam ut pulvinar erat, eget tincidunt dolor. Nulla vel nibh dapibus, pellentesque felis nec, convallis quam. Morbi elit nibh, vehicula ut mi at, volutpat commodo nisl. Cras sed justo elementum, malesuada dui sed, tristique elit. Sed imperdiet pharetra erat, vel commodo nunc aliquet sed. Integer facilisis tellus non porta vulputate. Fusce sit amet porttitor est, eu mattis augue. Nunc pretium suscipit venenatis. Curabitur dictum maximus nibh at vulputate. In molestie nulla non arcu dictum tristique.
                </p>
                <p class="mb-5">In ut ex porta, vulputate purus ac, tristique risus. Nam mattis placerat auctor. Fusce imperdiet lorem metus, eget dignissim arcu euismod a. Etiam vitae commodo massa. Maecenas tempus mauris rutrum arcu lacinia, ut vehicula neque sodales. Ut mollis nisl quis dui interdum, in fringilla nunc tristique. Maecenas consectetur erat eget tellus ultricies aliquet. Sed ornare quis leo sit amet pulvinar. Duis vitae venenatis tortor. Nam pharetra elit ultrices cursus aliquet.</p>
              </div>
              <div class="item">
                <div class="card-box ">
                  <p><img src="frontend/images/icon/“ (1).svg" alt="" class="img-ful"> Suspendisse sollicitudin velit enim. Proin et quam blandit, auctor metus ac, laoreet nisl. Cras ut ornare mi. Maecenas imperdiet, urna eget ultricies porttitor, sem enim commodo tellus, id tincidunt nulla sem vel purus.</p>
                  <h5>Michel Anderson</h5>
                </div>
              </div>
              <div class="item mt-75">
                <h3>Nam a velit id velit pulvinar rutrum</h3>
                <img class="img-ful mb-25" src="frontend/images/blog/Rectangle 29-1.png" alt="">
                <p class="mb-5">Vivamus vitae velit ac lorem volutpat convallis. Sed tempor euismod neque in porta. Nam ut pulvinar erat, eget tincidunt dolor. Nulla vel nibh dapibus, pellentesque felis nec, convallis quam. Morbi elit nibh, vehicula ut mi at, volutpat commodo nisl. Cras sed justo elementum, malesuada dui sed, tristique elit. Sed imperdiet pharetra erat, vel commodo nunc aliquet sed. Integer facilisis tellus non porta vulputate. Fusce sit amet porttitor est, eu mattis augue. Nunc pretium suscipit venenatis. Curabitur dictum maximus nibh at vulputate. In molestie nulla non arcu dictum tristique.
                </p>
                <p class="mb-5">
                  In ut ex porta, vulputate purus ac, tristique risus. Nam mattis placerat auctor. Fusce imperdiet lorem metus, eget dignissim arcu euismod a. Etiam vitae commodo massa. Maecenas tempus mauris rutrum arcu lacinia, ut vehicula neque sodales. Ut mollis nisl quis dui interdum, in fringilla nunc tristique. Maecenas consectetur erat eget tellus ultricies aliquet. Sed ornare quis leo sit amet pulvinar. Duis vitae venenatis tortor. Nam pharetra elit ultrices cursus aliquet.
                </p>
                <div class="list-ner mb-40">
                  <ul>
                    <li>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Vivamus vitae velit ac lorem volutpat convallis. 
                    </li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Sed tempor euismod neque in porta. Nam ut pulvinar erat, eget tincidunt dolor. 
                    </li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Nulla vel nibh dapibus, pellentesque felis nec, convallis quam. Morbi elit nibh, vehicula ut mi at, volutpat commodo nisl. 
                    </li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Cras sed justo elementum, malesuada dui sed, tristique elit. Sed imperdiet pharetra erat, vel commodo nunc aliquet sed. 
                    </li>
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                      </svg>
                      Integer facilisis tellus non porta vulputate. 
                    </li>
                  </ul>
                </div>
                <p class="mb-5">Vivamus vitae velit ac lorem volutpat convallis. Sed tempor euismod neque in porta. Nam ut pulvinar erat, eget tincidunt dolor. Nulla vel nibh dapibus, pellentesque felis nec, convallis quam. Morbi elit nibh, vehicula ut mi at, volutpat commodo nisl. Cras sed justo elementum, malesuada dui sed, tristique elit. Sed imperdiet pharetra erat, vel commodo nunc aliquet sed. Integer facilisis tellus non porta vulputate. Fusce sit amet porttitor est, eu mattis augue. Nunc pretium suscipit venenatis. Curabitur dictum maximus nibh at vulputate. In molestie nulla non arcu dictum tristique.
                </p>
                <p class="mb-5">
                  In ut ex porta, vulputate purus ac, tristique risus. Nam mattis placerat auctor. Fusce imperdiet lorem metus, eget dignissim arcu euismod a. Etiam vitae commodo massa. Maecenas tempus mauris rutrum arcu lacinia, ut vehicula neque sodales. Ut mollis nisl quis dui interdum, in fringilla nunc tristique. Maecenas consectetur erat eget tellus ultricies aliquet. Sed ornare quis leo sit amet pulvinar. Duis vitae venenatis tortor. Nam pharetra elit ultrices cursus aliquet.
                </p>
              </div>
              <div class="item">
                <div class="table">
                  <h5>Pellentesque egestas neque id condimentum placerat.</h5>
                  <table cellspacing="0" celpedding="0" border="0">
                    <thead>
                      <tr>
                        <th>Fus mperdie</th>
                        <th>Malesuada congue</th>
                        <th>Pulvinar era</th>
                        <th>Nulla vel nibh</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Nullaedapibus pellentesque  </td>
                        <td>Morbi elit nibh, vehicula ut mi atcommodo nisl</td>
                        <td>
                          <ul>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Eehicula ut mi atcommodo nisl</li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Fusce imperdiet lorem metus eget niss.</li>
                          </ul>
                        </td>
                        <td>
                          <ul>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Eehicula ut mi atcommodo nisl</li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Fusce imperdiet lorem metus eget niss.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Nullaedapibus pellentesque  </td>
                        <td>Morbi elit nibh, vehicula ut mi atcommodo nisl</td>
                        <td>
                          <ul>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Eehicula ut mi atcommodo nisl</li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Fusce imperdiet lorem metus eget niss.</li>
                          </ul>
                        </td>
                        <td>
                          <ul>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Eehicula ut mi atcommodo nisl</li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Fusce imperdiet lorem metus eget niss.</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>Nullaedapibus pellentesque  </td>
                        <td>Morbi elit nibh, vehicula ut mi atcommodo nisl</td>
                        <td>
                          <ul>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Eehicula ut mi atcommodo nisl</li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Fusce imperdiet lorem metus eget niss.</li>
                          </ul>
                        </td>
                        <td>
                          <ul>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Eehicula ut mi atcommodo nisl</li>
                            <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                              </svg>
                              Fusce imperdiet lorem metus eget niss.</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="item mt-75">
                <h3>Nam a velit id velit pulvinar</h3>
                <img class="img-ful mb-30" src="frontend/images/blog/Rectangle 282.png" alt="">
                <p class="mb-5">Vivamus vitae velit ac lorem volutpat convallis. Sed tempor euismod neque in porta. Nam ut pulvinar erat, eget tincidunt dolor. Nulla vel nibh dapibus, pellentesque felis nec, convallis quam. Morbi elit nibh, vehicula ut mi at, volutpat commodo nisl. Cras sed justo elementum, malesuada dui sed, tristique elit. Sed imperdiet pharetra erat, vel commodo nunc aliquet sed. Integer facilisis tellus non porta vulputate. Fusce sit amet porttitor est, eu mattis augue. Nunc pretium suscipit venenatis. Curabitur dictum maximus nibh at vulputate. In molestie nulla non arcu dictum tristique.
                </p>
                <p class="mb-5">
                  In ut ex porta, vulputate purus ac, tristique risus. Nam mattis placerat auctor. Fusce imperdiet lorem metus, eget dignissim arcu euismod a. Etiam vitae commodo massa. Maecenas tempus mauris rutrum arcu lacinia, ut vehicula neque sodales. Ut mollis nisl quis dui interdum, in fringilla nunc tristique. Maecenas consectetur erat eget tellus ultricies aliquet. Sed ornare quis leo sit amet pulvinar. Duis vitae venenatis tortor. Nam pharetra elit ultrices cursus aliquet.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="right-pana">
              <div class="item">
                <div class="card-box">
                  <form action="#">
                    <div class="form-group">
                      <input type="search" name="" id="" placeholder="Search">
                      <button type="submit" class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                      </svg></button>
                    </div>
                  </form>
                </div>
              </div>

              <div class="item-tab">
                <nav>
                  <ul>
                    <li>
                      <button class="btn"  @click="currentTabComponent = 'PopularPage'" :class="{active: currentTabComponent === 'PopularPage' }">Popular</button>
                    </li>
                    <li>
                      <button class="btn"  @click="currentTabComponent = 'RecentPage'" :class="{active: currentTabComponent === 'RecentPage' }">Recent</button>
                    </li>
                  </ul>
                </nav>
                     <keep-alive>
                        <component :is="currentTabComponent"></component>
                     </keep-alive>
                <!-- <div class="tab-content">
                  <div class="tab-item">
                    <div class="list-item">
                      <img src="frontend/images/blog/angle 41.png" alt="">
                      <div class="ttr">
                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-week" viewBox="0 0 16 16">
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                          <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                        </svg> 23 Mar, 2022</p>
                        <h6>Nam a velit id velithasellus eget ligntesque egestas neque </h6>
                      </div>
                    </div>
                    <div class="list-item">
                      <img src="frontend/images/blog/angle 41 (1).png" alt="">
                      <div class="ttr">
                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-week" viewBox="0 0 16 16">
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                          <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                        </svg> 23 Mar, 2022</p>
                        <h6>Nam a velit id velithasellus eget ligntesque egestas neque </h6>
                      </div>
                    </div>
                    <div class="list-item">
                      <img src="frontend/images/blog/angle 41 (2).png" alt="">
                      <div class="ttr">
                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-week" viewBox="0 0 16 16">
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                          <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                        </svg> 23 Mar, 2022</p>
                        <h6>Nam a velit id velithasellus eget ligntesque egestas neque </h6>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="item-news">
                <h5>Newsletter Signup</h5>
                <p>Get Hooked! Sign Up to get the latest catch sent to your inbox.</p>
                <form action="#">
                  <div class="form-grp">
                    <input type="email" name="" id="" placeholder="Enter email address">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#6E6E6F" class="masgic bi bi-envelope-fill" viewBox="0 0 16 16">
                      <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                    </svg>
                  </div>
                  <button type="submit" class="btn">Subscribe</button>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- <div class="post-card">
        <div class="heading">
          <div class="container">
            <h4>Related Posts</h4>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="item">
                <img class="img-ful" src="frontend/images/blog/Rectangleitem-1.png" alt="">
                <div class="ttpx">
                  <span class="date">10 Jan, 2022</span>
                  <h6>Mauris vitae mi aucdignissim lacus ut, tincidunt magna.</h6>
                  <p>Donec sit amet turpis accumsan iaculis. Quisque ssem sed pulvinar condimentum, sapien justo.</p>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="item">
                <img class="img-ful" src="frontend/images/blog/Rectangleitem-2.png" alt="">
                <div class="ttpx">
                  <span class="date">10 Jan, 2022</span>
                  <h6>Mauris vitae mi aucdignissim lacus ut, tincidunt magna.</h6>
                  <p>Donec sit amet turpis accumsan iaculis. Quisque ssem sed pulvinar condimentum, sapien justo.</p>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="item">
                <img class="img-ful" src="frontend/images/blog/Rectangleitem-3.png" alt="">
                <div class="ttpx">
                  <span class="date">10 Jan, 2022</span>
                  <h6>Mauris vitae mi aucdignissim lacus ut, tincidunt magna.</h6>
                  <p>Donec sit amet turpis accumsan iaculis. Quisque ssem sed pulvinar condimentum, sapien justo.</p>
                  <a href="#">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> -->


    </div> 


    <!-- Blog Details Section end  -->








     <!-- Contact Section start here  -->

     <div class="contact-section">
       <div class="container">
         <div class="row">
           <div class="col-lg-6">
             <div class="atcle-area">
               <h3>Stay <strong> Connected</strong> With Us</h3>
               <p>We study market trends and customer reach. Our team is creative, strategic, and equipped with the latest skills and tools required to be a major game changer in the world of e-mail marketing.</p>

               <div class="count-down">
                 <div class="item">
                   <h2>1,000+</h2>
                   <p>Project completed</p>
                 </div>
                 <div class="item">
                   <h2>910+</h2>
                   <p>Satisfied Clients</p>
                 </div>
               </div>

             </div>
           </div>
           <div class="col-lg-6">
             <div class="fild-cnt">
               <h3><strong>Free</strong> Consultation</h3>
               <form action="#">
                 <div class="form-group">
                   <input type="text" placeholder="Full Name">
                   <input type="email" placeholder="Email Address">
                 </div>
                 <div class="form-group">
                  <input type="text" placeholder="Phone No">
                  <input type="text" placeholder="Subject">
                </div>
                <button class="btn" type="submit">Get Consultation</button>
               </form>
             </div>
           </div>
         </div>
       </div>
       <div class="container meet-cart-align">
         <div class="meet-cart">

           <div class="row">
            <div class="col-lg-7">
              <div class="text-ast">
                <h2>Meet Us For <strong>One Stop Digital Service Solutions</strong></h2>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="cntct-btn">
                <a href="#" class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                 <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
               </svg> +1 727 300 6244</a>
              </div>
            </div>
           </div>

         </div>
         <div class="bg-img-bbx"></div>


       </div>
     </div>
    
     <!-- Contact Section end  -->



     <!-- Footer section start here  -->
     <FooTer/>
     <!-- <footer class="main-footer">

      <div class="container">
        <div class="row">
          <div class="col-lg-3">
            <div class="item">
              <img src="frontend/images/logo/logo.png" class="frt-logo" alt="">
              <p>We are a web-based marketing agency with expertise in e-mail marketing, strategic planning, and blueprint road mapping.</p>
              <div class="icon-column">
                <a href="#" class="btn">
                  <img src="frontend/images/icon/facebook.svg" alt="">
                </a>
                <a href="#" class="btn">
                  <img src="frontend/images/icon/twi.svg" alt="">
                </a>
                <a href="#" class="btn">
                  <img src="frontend/images/icon/link.svg" alt="">
                </a>
                <a href="#" class="btn">
                  <img src="frontend/images/icon/be.svg" alt="">
                </a>
                
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="item">
              <h4>Quick Link</h4>
              <ul>
                <li>
                  <router-link to="/">
                  <a href="#">Home </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/aboutview"> 
                  <a href="#">About Us </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/servicepage">
                  <a href="#">Services</a>
                  </router-link>
                </li>
                <li>
                  <a href="#">Our Team </a>
                </li>
                <li>
                  <router-link to="/blogpage">
                  <a href="#">Blog </a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/contactpage">
                  <a href="#">Contact</a>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="item">
              <h4>Contact</h4>
              <ul>
                <li>
                  <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
                  </svg>  7901 4th ST N STE 8022 St. Petersburg, FL 33702</a>
                </li>
                <li>
                  <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
                  </svg>  +1 727 300 6244</a>
                </li>
                <li>
                  <a href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
                  </svg> david@bigwavedevelopment.com</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="item">
              <h4>Newsletter</h4>
              <p>Want to know what we’re up to? Sign up for the newsletter to stay updated.</p>
              <form action="#">
                <div class="form-group">
                  <input type="email" placeholder="Enter email address">
                  <button class="btn" type="submit"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send-fill" viewBox="0 0 16 16">
                    <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z"/>
                  </svg></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="copyrgt-area">
        <div class="container">
          <div class="content-ftr">
            <div class="text">
              <h6>Copyright @2024 <span> Big Wave Development</span> All Rights Reserved.</h6>
            </div>
            <div class="text-min">
              <a href="#">Privacy Policy</a> | <a href="#">Terms and Conditions</a> </div>
          </div>
        </div>
      </div>

     </footer> -->
</template>


<script>
  import FooTer from '../views/FooTer.vue'
  import NavBar from '../views/NavBar.vue'
  import PopularPage from '../views/PopularPage.vue'
  import RecentPage from '../views/RecentPage.vue'
   export default {
            name: "BlogDetails",
            components: {
                PopularPage,
                RecentPage,
                NavBar,
                FooTer
            },
        data() {
            return {
            currentTabComponent: "PopularPage",
            };
        }
        };

</script>

<style scoped>
  


</style>

